@use "~booksprout-app/src/css/quasar.variables.sass" as *
@use "~booksprout-app/src/css/mixins/media.sass" as *
@use '~booksprout-app/src/css/functions/rem-fn' as *
@use '~booksprout-app/src/css/functions/line-height-fn' as *
@use 'src/css/functions/get-color' as *
@use '~booksprout-app/src/css/mixins/media' as media-query
@use 'src/css/variables/border-radius' as *
@use 'src/css/variables/box-shadow' as *
@use 'src/css/variables/media'

.bs-website-error
  margin-block-end: rem-fn(50)
  text-align: center

  main
    padding-inline: rem-fn(50)

    @include media-query.from(media.$md-min)
      column-gap: rem-fn(95)
      display: grid
      grid-template-columns: rem-fn(503) 1fr
      margin-inline: auto
      max-inline-size: rem-fn(media.$content-max-width)
      padding-inline: rem-fn(40)
      text-align: left

  h1
    font-size: rem-fn(94)
    line-height: line-height(128, 94)
    margin-block-end: rem-fn(5)

  p
    margin-block-end: rem-fn(30)

    @include media-query.from(media.$md-min)
      &:first-of-type
        margin-block-end: rem-fn(60)

  ul
    display: flex
    flex-flow: column
    gap: rem-fn(20) rem-fn(75)
    list-style: none
    margin: 0
    padding: 0

    @include media-query.from(media.$md-min)
      flex-flow: row

.bs-w-header-error
  border-block-end: 0
  justify-content: center
  padding-block: rem-fn(40)

  @include media-query.from(media.$md-min)
    justify-content: inherit
    margin-block-end: rem-fn(65)
    padding-block: rem-fn(45)

.bs-w-leaves
  block-size: rem-fn(220)
  inline-size: rem-fn(206)
  margin-block: 0
  margin-inline: auto
  position: relative

  @include media-query.from(media.$md-min)
    block-size: rem-fn(535)
    inline-size: rem-fn(503)

.bs-w-leaf
  align-items: center
  background-color: get-color(bs-w)
  border-start-end-radius: $border-radius-base
  border-end-end-radius: $border-radius-base
  box-shadow: $box-shadow-base
  block-size: rem-fn(95)
  color: get-color(bs-w)
  display: flex
  inline-size: rem-fn(67)
  justify-content: center
  position: absolute

  svg
    color: #E5E5E5

  &:first-child
    background-color: get-color(bs-g)
    left: rem-fn(77)
    top: rem-fn(53)

    svg
      color: get-color(bs-w)
      block-size: rem-fn(22)
      inline-size: rem-fn(30)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(232)
      inline-size: rem-fn(164)
      left: rem-fn(189)
      top: rem-fn(129)

      svg
        block-size: rem-fn(54)
        inline-size: rem-fn(74)

  &:nth-child(2)
    block-size: rem-fn(46)
    inline-size: rem-fn(32)
    top: 0
    right: 0

    svg
      block-size: rem-fn(10)
      inline-size: rem-fn(14)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(112)
      inline-size: rem-fn(78)

      svg
        block-size: rem-fn(24)
        inline-size: rem-fn(34)

  &:nth-child(3)
    block-size: rem-fn(34)
    inline-size: rem-fn(24)
    bottom: rem-fn(55)
    right: rem-fn(4)

    svg
      block-size: rem-fn(7)
      inline-size: rem-fn(9)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(82)
      inline-size: rem-fn(58)
      bottom: rem-fn(134)
      right: rem-fn(10)

      svg
        block-size: rem-fn(16)
        inline-size: rem-fn(22)

  &:nth-child(4)
    block-size: rem-fn(50)
    inline-size: rem-fn(35)
    bottom: 0
    left: rem-fn(40)

    svg
      block-size: rem-fn(10)
      inline-size: rem-fn(14)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(123)
      inline-size: rem-fn(86)
      left: rem-fn(98)

      svg
        block-size: rem-fn(24)
        inline-size: rem-fn(34)

  &:nth-child(5)
    block-size: rem-fn(31)
    inline-size: rem-fn(22)
    bottom: rem-fn(85)
    left: rem-fn(2)

    svg
      block-size: rem-fn(7)
      inline-size: rem-fn(9)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(76)
      inline-size: rem-fn(54)
      bottom: rem-fn(208)
      left: rem-fn(5)

      svg
        block-size: rem-fn(16)
        inline-size: rem-fn(22)

  &:nth-child(6)
    block-size: rem-fn(57)
    inline-size: rem-fn(40)
    top: rem-fn(8)
    left: 0

    svg
      block-size: rem-fn(17)
      inline-size: rem-fn(12)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(140)
      inline-size: rem-fn(98)
      top: rem-fn(21)

      svg
        block-size: rem-fn(30)
        inline-size: rem-fn(42)

  &:nth-child(7)
    block-size: rem-fn(19)
    inline-size: rem-fn(13)
    top: rem-fn(14)
    right: rem-fn(88)

    svg
      block-size: rem-fn(6)
      inline-size: rem-fn(4)

    @include media-query.from(media.$md-min)
      block-size: rem-fn(46)
      inline-size: rem-fn(32)
      top: rem-fn(34)
      right: rem-fn(216)

      svg
        block-size: rem-fn(10)
        inline-size: rem-fn(14)
